"use strict"
/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './header.template.js';
import TEMPLATE_CSS from './header.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Header Component
 * 	----------------------------------------------------------------------------------------------
 */
 export default  class AppHeader extends LitElement {
    
	constructor() {
		super();

		this.config = {
			sticky: true,
			// when true, header will be hidden when the autoHideOffset is reached
			autoHide: true,
			autoHideOffset: 300,
			autoHideShowOffset: 0,
			glidingOffset: 100, // value in px, when the is-gliding class is set
		};

		this.ticking = false;
		this.currentScrollY = 0;
		this.lastScrollY = 0;
	}

	show() {
		this.classList.remove("is-hidden");
	}
	hide() {
		this.classList.add("is-hidden");
	}

	gliding(state) {
		if (state == false) {
			this.classList.remove("is-gliding")
		} else {
			this.classList.add("is-gliding")
		}
	}
	
	updater() {
		
		if(this.currentScrollY > 0) {			
			// set the sticky / gliding classes
			if (this.currentScrollY > this.config.glidingOffset) {
				this.gliding();
			} else if (this.currentScrollY < this.config.glidingOffset) {
				this.gliding(false);
			}

			// set the autohide classes if autohide is true
			if(this.config.autoHide) {
				if (this.currentScrollY < this.config.autoHideOffset) {
					this.show();
				} else if (this.currentScrollY < this.lastScrollY) {
					this.show();
				} else if (this.currentScrollY > this.lastScrollY) {
					this.hide(); 
				}
			}
		} else {
			this.gliding(false);
		}
	
		this.lastScrollY = this.currentScrollY;
		this.ticking = false;
	}


	scrolling() {
		this.currentScrollY = window.pageYOffset;	
		//console.log("scrolly: "+ window.pageYOffset);
		if (!this.ticking) {
			requestAnimationFrame(() => { this.updater(); });
		}
		this.ticking = true;
	}
	
	firstUpdated() {
		if(this.config.sticky) {
			document.addEventListener("scroll", () => { this.scrolling(); }, {passive: true});
		}
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}
}

customElements.define('app-header', AppHeader);