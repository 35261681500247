import {html} from 'lit';

export default function(self) {
    return html`
        <nav class="language-switch">
            ${self.languages.map((item) => { 
                return html`
                    <a href="/${(app.i18n.getDefaultLanguage() === item ? '' : item)}" @click="${self.changeLanguage}" class="${self.currentLanguage === item ? 'is-active' : ''}" data-value="${item}" lang="${item}" hreflang="${item}">${item.toUpperCase()}</a>
                `
            })}
        </nav>
    `;
}