"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './icon.template.js';
import TEMPLATE_CSS from './icon.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Button Component
 * 	----------------------------------------------------------------------------------------------
 */
class AdmIcon extends LitElement {
    
	static get properties() {
		return {
			icon: {type: String},
			path: {type: String},
		}; 
	}


	constructor() {
		super();
		this.path = "/assets/img/svg/icons/icons.svg"
		this.icon = "#md";
		this.iconpath = this.path + this.icon;
	}

	firstUpdated() {
		
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}
}

customElements.define('adm-icon', AdmIcon);

export default AdmIcon;