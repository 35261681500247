"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import { AdmFetch } from "@minddraft/adamantium-frontend-js";
import { APIError } from "./APIError";

/**
 *	----------------------------------------------------------------------------------------------
 *	Class API 
 * 	----------------------------------------------------------------------------------------------
 */
 class API extends AdmFetch {

	constructor(config){ 		
		const options = { 
			headers: new Headers({'content-type': 'application/json', "accept": 'application/json', 'X-Access-Token': config.key})
		};
		super(options);

		this.endpoint = config.endpoint;
	}

	get(url, data) {
    	return this.fetchJSON(this.endpoint + url, 'GET', data).catch(this._apiErrorInterceptor);
  	}

	post(url, data) {
		return this.fetchJSON(this.endpoint + url, 'POST', data).catch(this._apiErrorInterceptor);
	}
	
	put(url, data) {
    	return this.fetchJSON(this.endpoint + url, 'PUT', data).catch(this._apiErrorInterceptor);
	}
	
	del(url, data) {
    	return this.fetchJSON(this.endpoint + url, 'DELETE', data).catch(this._apiErrorInterceptor);
	}

	_apiErrorInterceptor(err){
		const error = new APIError(err);
		return error;
	}
}

export {API};