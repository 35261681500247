/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {html} from 'lit';

/**
 *	----------------------------------------------------------------------------------------------
 *	Template
 * 	----------------------------------------------------------------------------------------------
 */
export default function(self) {
    return html`
       <div class="logo" aria-label="minddraft Logo">
            <svg id="logo-sign" width="122" height="98" viewBox="0 0 122 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>minddraft logo sign</title>
                <path d="M118.27 3.99332L10.3744 94.7175C8.17767 96.5646 4.88682 96.2204 3.11981 93.9588C1.3528 91.6971 1.81497 88.4207 4.13867 86.7361L118.27 3.99332Z" fill="url(#paint0_linear_1101_1386)"/>
                <path d="M111.38 26.2344L52.361 91.7334C50.3094 94.0103 46.6996 93.8835 44.8126 91.4684C43.2097 89.4167 43.4782 86.4722 45.4259 84.7444L111.38 26.2344Z" fill="url(#paint1_linear_1101_1386)"/>
                <path d="M73.0022 18.1517L20.2133 55.5164C18.1059 57.008 15.1985 56.5811 13.6089 54.5465C11.7312 52.1431 12.52 48.6229 15.2438 47.2507L73.0022 18.1517Z" fill="url(#paint2_linear_1101_1386)"/>
                <defs>
                <linearGradient id="paint0_linear_1101_1386" x1="118.27" y1="3.99332" x2="3.11981" y2="93.9588" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC445"/>
                <stop offset="1" stop-color="#E5822E"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1101_1386" x1="116.397" y1="32.655" x2="43.4126" y2="89.6765" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC445"/>
                <stop offset="1" stop-color="#E5822E"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1101_1386" x1="68.7602" y1="12.7222" x2="14.2224" y2="55.3318" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC445"/>
                <stop offset="1" stop-color="#E5822E"/>
                </linearGradient>
                </defs>
            </svg>
            <svg id="logo-word" width="143" height="27" viewBox="0 0 143 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>minddraft logo word</title>
                <path d="M0.0341797 25.6414V10.7264C0.0341797 10.3918 0.147715 10.1169 0.374785 9.90182C0.601855 9.67475 0.882704 9.56122 1.21733 9.56122C1.55196 9.56122 1.82684 9.67475 2.04196 9.90182C2.26903 10.1169 2.38256 10.3918 2.38256 10.7264V11.9096C2.71719 11.1806 3.30877 10.5711 4.15729 10.0811C5.01777 9.5911 6.01568 9.3461 7.15103 9.3461C8.59711 9.3461 9.81611 9.57915 10.8081 10.0452C11.8 10.5113 12.529 11.1328 12.9951 11.9096C14.0826 10.2006 15.8693 9.3461 18.3551 9.3461C19.4427 9.3461 20.4047 9.47756 21.2413 9.74049C22.0779 9.99146 22.7472 10.3201 23.2491 10.7264C23.751 11.1328 24.1574 11.6288 24.4681 12.2144C24.7908 12.8 25.0059 13.3856 25.1135 13.9712C25.233 14.5448 25.2927 15.1663 25.2927 15.8355V25.6414C25.2927 25.976 25.1792 26.2568 24.9521 26.4839C24.737 26.699 24.4621 26.8066 24.1275 26.8066C23.7929 26.8066 23.512 26.699 23.2849 26.4839C23.0579 26.2568 22.9443 25.976 22.9443 25.6414V16.3195C22.9443 14.742 22.5978 13.5349 21.9046 12.6984C21.2234 11.8498 20.0402 11.4256 18.3551 11.4256C16.8135 11.4256 15.6602 11.814 14.8953 12.5908C14.1424 13.3557 13.7659 14.4372 13.7659 15.8355V25.6414C13.7659 25.976 13.6524 26.2568 13.4253 26.4839C13.2102 26.699 12.9353 26.8066 12.6007 26.8066C12.2661 26.8066 11.9852 26.699 11.7582 26.4839C11.5311 26.2568 11.4176 25.976 11.4176 25.6414V16.3195C11.4176 14.73 11.0889 13.517 10.4316 12.6804C9.77429 11.8439 8.59711 11.4256 6.90006 11.4256C5.27472 11.4256 4.11546 11.8737 3.4223 12.7701C2.72914 13.6545 2.38256 14.9213 2.38256 16.5705V25.6414C2.38256 25.976 2.26903 26.2568 2.04196 26.4839C1.82684 26.699 1.55196 26.8066 1.21733 26.8066C0.882704 26.8066 0.601855 26.699 0.374785 26.4839C0.147715 26.2568 0.0341797 25.976 0.0341797 25.6414Z" fill="white"/>
                <path d="M29.6489 25.6414V10.7264C29.6489 10.3918 29.7624 10.1169 29.9895 9.90182C30.2166 9.67475 30.4974 9.56122 30.832 9.56122C31.1667 9.56122 31.4415 9.67475 31.6567 9.90182C31.8837 10.1169 31.9973 10.3918 31.9973 10.7264V25.6414C31.9973 25.976 31.8837 26.2568 31.6567 26.4839C31.4415 26.699 31.1667 26.8066 30.832 26.8066C30.4974 26.8066 30.2166 26.699 29.9895 26.4839C29.7624 26.2568 29.6489 25.976 29.6489 25.6414Z" fill="white"/>
                <path d="M36.7837 25.6414V10.7264C36.7837 10.3918 36.8972 10.1169 37.1243 9.90182C37.3513 9.67475 37.6322 9.56122 37.9668 9.56122C38.3014 9.56122 38.5763 9.67475 38.7914 9.90182C39.0185 10.1169 39.132 10.3918 39.132 10.7264V11.9096C39.5025 11.1686 40.124 10.5591 40.9964 10.0811C41.8688 9.5911 42.8787 9.3461 44.026 9.3461C45.1135 9.3461 46.0637 9.47756 46.8763 9.74049C47.7009 9.99146 48.3523 10.3201 48.8303 10.7264C49.3203 11.1328 49.7147 11.6288 50.0135 12.2144C50.3122 12.8 50.5094 13.3796 50.605 13.9532C50.7126 14.5269 50.7664 15.1543 50.7664 15.8355V25.6414C50.7664 25.976 50.6529 26.2568 50.4258 26.4839C50.2107 26.699 49.9358 26.8066 49.6012 26.8066C49.2665 26.8066 48.9857 26.699 48.7586 26.4839C48.5315 26.2568 48.418 25.976 48.418 25.6414V16.3195C48.418 15.5427 48.3523 14.8794 48.2208 14.3297C48.1013 13.768 47.8802 13.2601 47.5575 12.8059C47.2468 12.3398 46.7986 11.9933 46.213 11.7662C45.6274 11.5391 44.8984 11.4256 44.026 11.4256C40.7634 11.4256 39.132 13.1406 39.132 16.5705V25.6414C39.132 25.976 39.0185 26.2568 38.7914 26.4839C38.5763 26.699 38.3014 26.8066 37.9668 26.8066C37.6322 26.8066 37.3513 26.699 37.1243 26.4839C36.8972 26.2568 36.7837 25.976 36.7837 25.6414Z" fill="white"/>
                <path d="M54.0828 18.1481C54.0828 12.2801 56.5387 9.3461 61.4506 9.3461C62.5501 9.3461 63.5421 9.57915 64.4264 10.0452C65.3108 10.5113 65.9861 11.073 66.4521 11.7303V1.54804C66.4521 1.21341 66.5597 0.938536 66.7748 0.723417C67.0019 0.496347 67.2827 0.382812 67.6174 0.382812C67.952 0.382812 68.2328 0.496347 68.4599 0.723417C68.687 0.938536 68.8005 1.21341 68.8005 1.54804V19.7614C68.8005 22.1038 68.1432 23.8965 66.8286 25.1394C65.514 26.3823 63.7751 27.0038 61.612 27.0038C59.1979 27.0038 57.3395 26.2688 56.0368 24.7988C54.7341 23.3169 54.0828 21.1 54.0828 18.1481ZM56.4312 18.1481C56.4312 22.6297 58.1581 24.8705 61.612 24.8705C63.0939 24.8705 64.2711 24.4403 65.1435 23.5798C66.0159 22.7193 66.4521 21.4465 66.4521 19.7614V14.7061C66.4163 14.6225 66.3565 14.509 66.2729 14.3655C66.2012 14.2102 66.0219 13.9473 65.7351 13.5768C65.4602 13.2063 65.1495 12.8776 64.8029 12.5908C64.4683 12.304 64.0141 12.047 63.4405 11.82C62.8668 11.5809 62.2573 11.4614 61.612 11.4614C59.9269 11.4614 58.6421 12.0172 57.7578 13.1286C56.8734 14.2401 56.4312 15.9132 56.4312 18.1481Z" fill="white"/>
                <path d="M72.1169 18.1481C72.1169 12.2801 74.5729 9.3461 79.4848 9.3461C80.5843 9.3461 81.5762 9.57915 82.4606 10.0452C83.345 10.5113 84.0202 11.073 84.4863 11.7303V1.54804C84.4863 1.21341 84.5938 0.938536 84.809 0.723417C85.036 0.496347 85.3169 0.382812 85.6515 0.382812C85.9861 0.382812 86.267 0.496347 86.4941 0.723417C86.7211 0.938536 86.8347 1.21341 86.8347 1.54804V19.7614C86.8347 22.1038 86.1774 23.8965 84.8627 25.1394C83.5481 26.3823 81.8092 27.0038 79.6461 27.0038C77.232 27.0038 75.3736 26.2688 74.0709 24.7988C72.7683 23.3169 72.1169 21.1 72.1169 18.1481ZM74.4653 18.1481C74.4653 22.6297 76.1923 24.8705 79.6461 24.8705C81.128 24.8705 82.3052 24.4403 83.1776 23.5798C84.0501 22.7193 84.4863 21.4465 84.4863 19.7614V14.7061C84.4504 14.6225 84.3907 14.509 84.307 14.3655C84.2353 14.2102 84.056 13.9473 83.7692 13.5768C83.4943 13.2063 83.1836 12.8776 82.837 12.5908C82.5024 12.304 82.0483 12.047 81.4746 11.82C80.901 11.5809 80.2915 11.4614 79.6461 11.4614C77.961 11.4614 76.6763 12.0172 75.7919 13.1286C74.9075 14.2401 74.4653 15.9132 74.4653 18.1481Z" fill="white"/>
                <path d="M91.6031 25.6414V10.7264C91.6031 10.3918 91.7167 10.1169 91.9437 9.90182C92.1708 9.67475 92.4517 9.56122 92.7863 9.56122C93.1209 9.56122 93.3958 9.67475 93.6109 9.90182C93.838 10.1169 93.9515 10.3918 93.9515 10.7264V12.3398C94.3339 11.4674 94.9733 10.7623 95.8697 10.2245C96.766 9.68671 97.8296 9.41781 99.0606 9.41781C99.3713 9.41781 99.6342 9.53134 99.8494 9.75841C100.076 9.97353 100.19 10.2365 100.19 10.5472C100.19 10.8579 100.076 11.1268 99.8494 11.3539C99.6342 11.5809 99.3713 11.6945 99.0606 11.6945C97.4233 11.6945 96.1625 12.2144 95.2781 13.2541C94.3937 14.2938 93.9515 15.6802 93.9515 17.4131V25.6414C93.9515 25.976 93.838 26.2568 93.6109 26.4839C93.3958 26.699 93.1209 26.8066 92.7863 26.8066C92.4517 26.8066 92.1708 26.699 91.9437 26.4839C91.7167 26.2568 91.6031 25.976 91.6031 25.6414Z" fill="white"/>
                <path d="M101.445 21.7513C101.445 20.1379 102.018 18.8711 103.166 17.9509C104.313 17.0306 105.879 16.5705 107.863 16.5705H113.043V15.5845C113.043 14.3416 112.655 13.3557 111.878 12.6267C111.101 11.8976 110.044 11.5331 108.705 11.5331C107.749 11.5331 106.942 11.6825 106.285 11.9813C105.64 12.2801 105.03 12.7581 104.456 13.4154C104.158 13.762 103.835 13.9353 103.488 13.9353C103.19 13.9353 102.939 13.8337 102.736 13.6306C102.532 13.4154 102.431 13.1585 102.431 12.8597C102.431 12.6446 102.491 12.4354 102.61 12.2323C103.16 11.3599 103.949 10.6607 104.976 10.1349C106.004 9.60902 107.247 9.3461 108.705 9.3461C110.808 9.3461 112.434 9.9078 113.581 11.0312C114.74 12.1546 115.32 13.6724 115.32 15.5845V25.6772C115.32 25.9879 115.206 26.2568 114.979 26.4839C114.752 26.699 114.483 26.8066 114.173 26.8066C113.862 26.8066 113.593 26.699 113.366 26.4839C113.151 26.2568 113.043 25.9879 113.043 25.6772V24.3506C112.601 25.1872 111.896 25.8386 110.928 26.3046C109.972 26.7707 108.926 27.0038 107.791 27.0038C105.819 27.0038 104.265 26.5437 103.13 25.6234C102.007 24.7032 101.445 23.4125 101.445 21.7513ZM103.829 21.7513C103.829 22.7313 104.176 23.4961 104.869 24.0459C105.562 24.5956 106.608 24.8705 108.006 24.8705C109.38 24.8705 110.563 24.4582 111.555 23.6336C112.547 22.809 113.043 21.6557 113.043 20.1738V18.6321H107.863C106.62 18.6321 105.634 18.895 104.905 19.4208C104.188 19.9467 103.829 20.7235 103.829 21.7513Z" fill="white"/>
                <path d="M118.403 11.7483C118.2 11.5331 118.099 11.2702 118.099 10.9595C118.099 10.6488 118.2 10.3858 118.403 10.1707C118.618 9.9556 118.881 9.84804 119.192 9.84804H120.447V7.67893C120.447 6.8304 120.567 6.03566 120.806 5.29469C121.056 4.55373 121.427 3.89045 121.917 3.30484C122.419 2.70729 123.082 2.23523 123.907 1.88864C124.743 1.54206 125.705 1.36877 126.793 1.36877C127.104 1.36877 127.367 1.47633 127.582 1.69145C127.797 1.90657 127.904 2.16949 127.904 2.48022C127.904 2.79095 127.797 3.05387 127.582 3.26899C127.367 3.47216 127.104 3.57374 126.793 3.57374C125.419 3.57374 124.409 3.94423 123.763 4.68519C123.118 5.41421 122.795 6.41212 122.795 7.67893V9.84804H126.148C126.458 9.84804 126.715 9.9556 126.918 10.1707C127.134 10.3858 127.241 10.6488 127.241 10.9595C127.241 11.2702 127.134 11.5331 126.918 11.7483C126.715 11.9514 126.458 12.053 126.148 12.053H122.795V25.6414C122.795 25.976 122.682 26.2568 122.455 26.4839C122.24 26.699 121.965 26.8066 121.63 26.8066C121.296 26.8066 121.015 26.699 120.788 26.4839C120.561 26.2568 120.447 25.976 120.447 25.6414V12.053H119.192C118.881 12.053 118.618 11.9514 118.403 11.7483Z" fill="white"/>
                <path d="M129.016 11.7483C128.813 11.5331 128.711 11.2702 128.711 10.9595C128.711 10.6488 128.813 10.3858 129.016 10.1707C129.231 9.9556 129.494 9.84804 129.805 9.84804H131.239V5.99383C131.239 5.6831 131.358 5.41421 131.597 5.18713C131.836 4.94811 132.111 4.8286 132.422 4.8286C132.745 4.8286 133.02 4.94214 133.247 5.16921C133.474 5.39628 133.587 5.67115 133.587 5.99383V9.84804H135.989C136.3 9.84804 136.557 9.9556 136.76 10.1707C136.975 10.3858 137.083 10.6488 137.083 10.9595C137.083 11.2702 136.975 11.5331 136.76 11.7483C136.557 11.9514 136.3 12.053 135.989 12.053H133.587V22.1815C133.587 22.9583 133.778 23.5619 134.161 23.9921C134.555 24.4104 135.075 24.6195 135.72 24.6195C136.043 24.6195 136.312 24.7271 136.527 24.9422C136.754 25.1454 136.868 25.4023 136.868 25.7131C136.868 26.0238 136.754 26.2867 136.527 26.5018C136.312 26.705 136.043 26.8066 135.72 26.8066C134.298 26.8066 133.193 26.3823 132.404 25.5338C131.627 24.6853 131.239 23.5679 131.239 22.1815V12.053H129.805C129.494 12.053 129.231 11.9514 129.016 11.7483Z" fill="white"/>
                <path d="M139.413 25.0849C139.413 23.9137 139.987 23.3281 141.134 23.3281C142.281 23.3281 142.855 23.9137 142.855 25.0849C142.855 26.2322 142.281 26.8059 141.134 26.8059C139.987 26.8059 139.413 26.2322 139.413 25.0849Z" fill="#F39748"/>
            </svg>

        </div>
    `;
}