/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {html} from 'lit';
import { navTo, pushGAEvent } from '../../../js/utils/Utils'

import '../../components/language-switch/language-switch.controller';

/**
 *	----------------------------------------------------------------------------------------------
 *	Template
 * 	----------------------------------------------------------------------------------------------
 */
export default function(self) {
    return html`
        
        
        <div data-container>
        
            <div class="footer-left">
                <div class="footer-logo">
                    <svg width="143" height="27" viewBox="0 0 143 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 25.2585V10.3436C0 10.009 0.113535 9.73413 0.340605 9.51901C0.567675 9.29194 0.848525 9.17841 1.18315 9.17841C1.51778 9.17841 1.79266 9.29194 2.00778 9.51901C2.23485 9.73413 2.34838 10.009 2.34838 10.3436V11.5268C2.68301 10.7978 3.27459 10.1883 4.12311 9.69828C4.98359 9.20828 5.9815 8.96329 7.11685 8.96329C8.56293 8.96329 9.78193 9.19633 10.7739 9.66242C11.7658 10.1285 12.4948 10.75 12.9609 11.5268C14.0485 9.81779 15.8351 8.96329 18.321 8.96329C19.4085 8.96329 20.3706 9.09475 21.2071 9.35767C22.0437 9.60864 22.713 9.9373 23.2149 10.3436C23.7169 10.75 24.1232 11.2459 24.4339 11.8315C24.7566 12.4171 24.9717 13.0027 25.0793 13.5883C25.1988 14.162 25.2585 14.7835 25.2585 15.4527V25.2585C25.2585 25.5932 25.145 25.874 24.9179 26.1011C24.7028 26.3162 24.4279 26.4238 24.0933 26.4238C23.7587 26.4238 23.4778 26.3162 23.2508 26.1011C23.0237 25.874 22.9102 25.5932 22.9102 25.2585V15.9367C22.9102 14.3592 22.5636 13.1521 21.8704 12.3156C21.1892 11.467 20.0061 11.0428 18.321 11.0428C16.7793 11.0428 15.626 11.4312 14.8611 12.208C14.1082 12.9729 13.7318 14.0544 13.7318 15.4527V25.2585C13.7318 25.5932 13.6182 25.874 13.3912 26.1011C13.176 26.3162 12.9012 26.4238 12.5665 26.4238C12.2319 26.4238 11.9511 26.3162 11.724 26.1011C11.4969 25.874 11.3834 25.5932 11.3834 25.2585V15.9367C11.3834 14.3472 11.0547 13.1342 10.3974 12.2976C9.74011 11.4611 8.56293 11.0428 6.86588 11.0428C5.24054 11.0428 4.08128 11.4909 3.38812 12.3873C2.69496 13.2716 2.34838 14.5385 2.34838 16.1877V25.2585C2.34838 25.5932 2.23485 25.874 2.00778 26.1011C1.79266 26.3162 1.51778 26.4238 1.18315 26.4238C0.848525 26.4238 0.567675 26.3162 0.340605 26.1011C0.113535 25.874 0 25.5932 0 25.2585Z" fill="white"/>
                        <path d="M29.6147 25.2585V10.3436C29.6147 10.009 29.7282 9.73413 29.9553 9.51901C30.1824 9.29194 30.4632 9.17841 30.7979 9.17841C31.1325 9.17841 31.4074 9.29194 31.6225 9.51901C31.8496 9.73413 31.9631 10.009 31.9631 10.3436V25.2585C31.9631 25.5932 31.8496 25.874 31.6225 26.1011C31.4074 26.3162 31.1325 26.4238 30.7979 26.4238C30.4632 26.4238 30.1824 26.3162 29.9553 26.1011C29.7282 25.874 29.6147 25.5932 29.6147 25.2585Z" fill="white"/>
                        <path d="M36.7495 25.2585V10.3436C36.7495 10.009 36.863 9.73413 37.0901 9.51901C37.3172 9.29194 37.598 9.17841 37.9326 9.17841C38.2673 9.17841 38.5421 9.29194 38.7573 9.51901C38.9843 9.73413 39.0979 10.009 39.0979 10.3436V11.5268C39.4683 10.7858 40.0898 10.1763 40.9622 9.69828C41.8347 9.20828 42.8445 8.96329 43.9918 8.96329C45.0794 8.96329 46.0295 9.09475 46.8421 9.35767C47.6668 9.60864 48.3181 9.9373 48.7961 10.3436C49.2861 10.75 49.6805 11.2459 49.9793 11.8315C50.2781 12.4171 50.4753 12.9968 50.5709 13.5704C50.6784 14.1441 50.7322 14.7715 50.7322 15.4527V25.2585C50.7322 25.5932 50.6187 25.874 50.3916 26.1011C50.1765 26.3162 49.9016 26.4238 49.567 26.4238C49.2324 26.4238 48.9515 26.3162 48.7244 26.1011C48.4974 25.874 48.3838 25.5932 48.3838 25.2585V15.9367C48.3838 15.1599 48.3181 14.4966 48.1866 13.9469C48.0671 13.3852 47.846 12.8773 47.5234 12.4231C47.2126 11.957 46.7645 11.6104 46.1789 11.3834C45.5933 11.1563 44.8642 11.0428 43.9918 11.0428C40.7292 11.0428 39.0979 12.7577 39.0979 16.1877V25.2585C39.0979 25.5932 38.9843 25.874 38.7573 26.1011C38.5421 26.3162 38.2673 26.4238 37.9326 26.4238C37.598 26.4238 37.3172 26.3162 37.0901 26.1011C36.863 25.874 36.7495 25.5932 36.7495 25.2585Z" fill="white"/>
                        <path d="M54.0486 17.7652C54.0486 11.8973 56.5046 8.96329 61.4165 8.96329C62.5159 8.96329 63.5079 9.19633 64.3923 9.66242C65.2766 10.1285 65.9519 10.6902 66.418 11.3475V1.16523C66.418 0.830598 66.5255 0.555724 66.7406 0.340605C66.9677 0.113535 67.2486 0 67.5832 0C67.9178 0 68.1987 0.113535 68.4257 0.340605C68.6528 0.555724 68.7663 0.830598 68.7663 1.16523V19.3786C68.7663 21.721 68.109 23.5137 66.7944 24.7566C65.4798 25.9995 63.7409 26.621 61.5778 26.621C59.1637 26.621 57.3053 25.886 56.0026 24.416C54.7 22.9341 54.0486 20.7171 54.0486 17.7652ZM56.397 17.7652C56.397 22.2469 58.1239 24.4877 61.5778 24.4877C63.0597 24.4877 64.2369 24.0575 65.1093 23.197C65.9818 22.3365 66.418 21.0637 66.418 19.3786V14.3233C66.3821 14.2397 66.3224 14.1261 66.2387 13.9827C66.167 13.8274 65.9877 13.5644 65.7009 13.194C65.426 12.8235 65.1153 12.4948 64.7687 12.208C64.4341 11.9212 63.9799 11.6642 63.4063 11.4372C62.8326 11.1981 62.2231 11.0786 61.5778 11.0786C59.8927 11.0786 58.608 11.6343 57.7236 12.7458C56.8392 13.8572 56.397 15.5304 56.397 17.7652Z" fill="white"/>
                        <path d="M72.0828 17.7652C72.0828 11.8973 74.5387 8.96329 79.4506 8.96329C80.5501 8.96329 81.542 9.19633 82.4264 9.66242C83.3108 10.1285 83.986 10.6902 84.4521 11.3475V1.16523C84.4521 0.830598 84.5597 0.555724 84.7748 0.340605C85.0018 0.113535 85.2827 0 85.6173 0C85.952 0 86.2328 0.113535 86.4599 0.340605C86.6869 0.555724 86.8005 0.830598 86.8005 1.16523V19.3786C86.8005 21.721 86.1432 23.5137 84.8286 24.7566C83.5139 25.9995 81.7751 26.621 79.6119 26.621C77.1978 26.621 75.3394 25.886 74.0368 24.416C72.7341 22.9341 72.0828 20.7171 72.0828 17.7652ZM74.4311 17.7652C74.4311 22.2469 76.1581 24.4877 79.6119 24.4877C81.0939 24.4877 82.271 24.0575 83.1435 23.197C84.0159 22.3365 84.4521 21.0637 84.4521 19.3786V14.3233C84.4162 14.2397 84.3565 14.1261 84.2728 13.9827C84.2011 13.8274 84.0219 13.5644 83.735 13.194C83.4602 12.8235 83.1494 12.4948 82.8029 12.208C82.4682 11.9212 82.0141 11.6642 81.4404 11.4372C80.8668 11.1981 80.2573 11.0786 79.6119 11.0786C77.9268 11.0786 76.6421 11.6343 75.7577 12.7458C74.8733 13.8572 74.4311 15.5304 74.4311 17.7652Z" fill="white"/>
                        <path d="M91.5689 25.2585V10.3436C91.5689 10.009 91.6825 9.73413 91.9096 9.51901C92.1366 9.29194 92.4175 9.17841 92.7521 9.17841C93.0867 9.17841 93.3616 9.29194 93.5767 9.51901C93.8038 9.73413 93.9173 10.009 93.9173 10.3436V11.957C94.2998 11.0846 94.9391 10.3795 95.8355 9.84169C96.7318 9.30389 97.7954 9.03499 99.0264 9.03499C99.3371 9.03499 99.6001 9.14853 99.8152 9.3756C100.042 9.59072 100.156 9.85364 100.156 10.1644C100.156 10.4751 100.042 10.744 99.8152 10.9711C99.6001 11.1981 99.3371 11.3117 99.0264 11.3117C97.3891 11.3117 96.1283 11.8315 95.2439 12.8713C94.3595 13.911 93.9173 15.2973 93.9173 17.0302V25.2585C93.9173 25.5932 93.8038 25.874 93.5767 26.1011C93.3616 26.3162 93.0867 26.4238 92.7521 26.4238C92.4175 26.4238 92.1366 26.3162 91.9096 26.1011C91.6825 25.874 91.5689 25.5932 91.5689 25.2585Z" fill="white"/>
                        <path d="M101.411 21.3685C101.411 19.7551 101.984 18.4883 103.132 17.568C104.279 16.6478 105.844 16.1877 107.828 16.1877H113.009V15.2017C113.009 13.9588 112.621 12.9729 111.844 12.2439C111.067 11.5148 110.009 11.1503 108.671 11.1503C107.715 11.1503 106.908 11.2997 106.251 11.5985C105.605 11.8973 104.996 12.3753 104.422 13.0326C104.124 13.3792 103.801 13.5525 103.454 13.5525C103.155 13.5525 102.905 13.4509 102.701 13.2477C102.498 13.0326 102.397 12.7757 102.397 12.4769C102.397 12.2618 102.456 12.0526 102.576 11.8495C103.126 10.977 103.914 10.2779 104.942 9.75206C105.97 9.22621 107.213 8.96329 108.671 8.96329C110.774 8.96329 112.4 9.52499 113.547 10.6484C114.706 11.7718 115.286 13.2896 115.286 15.2017V25.2944C115.286 25.6051 115.172 25.874 114.945 26.1011C114.718 26.3162 114.449 26.4238 114.139 26.4238C113.828 26.4238 113.559 26.3162 113.332 26.1011C113.117 25.874 113.009 25.6051 113.009 25.2944V23.9678C112.567 24.8044 111.862 25.4557 110.894 25.9218C109.938 26.3879 108.892 26.621 107.757 26.621C105.785 26.621 104.231 26.1609 103.096 25.2406C101.972 24.3204 101.411 23.0297 101.411 21.3685ZM103.795 21.3685C103.795 22.3485 104.141 23.1133 104.835 23.6631C105.528 24.2128 106.573 24.4877 107.972 24.4877C109.346 24.4877 110.529 24.0754 111.521 23.2508C112.513 22.4261 113.009 21.2729 113.009 19.7909V18.2493H107.828C106.585 18.2493 105.599 18.5122 104.87 19.038C104.153 19.5639 103.795 20.3407 103.795 21.3685Z" fill="white"/>
                        <path d="M118.369 11.3654C118.166 11.1503 118.064 10.8874 118.064 10.5767C118.064 10.266 118.166 10.003 118.369 9.78791C118.584 9.57279 118.847 9.46523 119.158 9.46523H120.413V7.29612C120.413 6.44759 120.532 5.65285 120.771 4.91188C121.022 4.17092 121.393 3.50763 121.883 2.92203C122.385 2.32448 123.048 1.85241 123.873 1.50583C124.709 1.15925 125.671 0.985962 126.759 0.985962C127.07 0.985962 127.332 1.09352 127.548 1.30864C127.763 1.52376 127.87 1.78668 127.87 2.09741C127.87 2.40814 127.763 2.67106 127.548 2.88618C127.332 3.08935 127.07 3.19093 126.759 3.19093C125.384 3.19093 124.375 3.56141 123.729 4.30238C123.084 5.03139 122.761 6.0293 122.761 7.29612V9.46523H126.113C126.424 9.46523 126.681 9.57279 126.884 9.78791C127.099 10.003 127.207 10.266 127.207 10.5767C127.207 10.8874 127.099 11.1503 126.884 11.3654C126.681 11.5686 126.424 11.6702 126.113 11.6702H122.761V25.2585C122.761 25.5932 122.648 25.874 122.421 26.1011C122.205 26.3162 121.931 26.4238 121.596 26.4238C121.261 26.4238 120.98 26.3162 120.753 26.1011C120.526 25.874 120.413 25.5932 120.413 25.2585V11.6702H119.158C118.847 11.6702 118.584 11.5686 118.369 11.3654Z" fill="white"/>
                        <path d="M128.982 11.3654C128.779 11.1503 128.677 10.8874 128.677 10.5767C128.677 10.266 128.779 10.003 128.982 9.78791C129.197 9.57279 129.46 9.46523 129.77 9.46523H131.205V5.61102C131.205 5.30029 131.324 5.03139 131.563 4.80432C131.802 4.5653 132.077 4.44579 132.388 4.44579C132.71 4.44579 132.985 4.55933 133.212 4.7864C133.439 5.01347 133.553 5.28834 133.553 5.61102V9.46523H135.955C136.266 9.46523 136.523 9.57279 136.726 9.78791C136.941 10.003 137.049 10.266 137.049 10.5767C137.049 10.8874 136.941 11.1503 136.726 11.3654C136.523 11.5686 136.266 11.6702 135.955 11.6702H133.553V21.7987C133.553 22.5755 133.744 23.1791 134.127 23.6093C134.521 24.0276 135.041 24.2367 135.686 24.2367C136.009 24.2367 136.278 24.3443 136.493 24.5594C136.72 24.7626 136.834 25.0195 136.834 25.3303C136.834 25.641 136.72 25.9039 136.493 26.119C136.278 26.3222 136.009 26.4238 135.686 26.4238C134.264 26.4238 133.159 25.9995 132.37 25.151C131.593 24.3025 131.205 23.185 131.205 21.7987V11.6702H129.77C129.46 11.6702 129.197 11.5686 128.982 11.3654Z" fill="white"/>
                        <path d="M139.379 24.7021C139.379 23.5309 139.953 22.9453 141.1 22.9453C142.247 22.9453 142.821 23.5309 142.821 24.7021C142.821 25.8494 142.247 26.4231 141.1 26.4231C139.953 26.4231 139.379 25.8494 139.379 24.7021Z" fill="#F39748"/>
                    </svg>
                </div>
            </div>
            <div class="footer-right">
            
                <div class="footer-image">
                    <div class="stage">
                        <app-parallax-image>

                            <img src="/assets/img/footer.webp" class="parallax-image" width="996" height="269" loading="lazy" srcset="
                            /assets/img/footer.webp 1000w,
                            /assets/img/footer-2x.webp 2000w,
                            /assets/img/footer-3x.webp 3000w," alt="decorational footer image"> 

                        </app-parallax-image>
                    </div>
                </div>

                <div class="footer-logo">
                    <svg width="143" height="27" viewBox="0 0 143 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 25.2585V10.3436C0 10.009 0.113535 9.73413 0.340605 9.51901C0.567675 9.29194 0.848525 9.17841 1.18315 9.17841C1.51778 9.17841 1.79266 9.29194 2.00778 9.51901C2.23485 9.73413 2.34838 10.009 2.34838 10.3436V11.5268C2.68301 10.7978 3.27459 10.1883 4.12311 9.69828C4.98359 9.20828 5.9815 8.96329 7.11685 8.96329C8.56293 8.96329 9.78193 9.19633 10.7739 9.66242C11.7658 10.1285 12.4948 10.75 12.9609 11.5268C14.0485 9.81779 15.8351 8.96329 18.321 8.96329C19.4085 8.96329 20.3706 9.09475 21.2071 9.35767C22.0437 9.60864 22.713 9.9373 23.2149 10.3436C23.7169 10.75 24.1232 11.2459 24.4339 11.8315C24.7566 12.4171 24.9717 13.0027 25.0793 13.5883C25.1988 14.162 25.2585 14.7835 25.2585 15.4527V25.2585C25.2585 25.5932 25.145 25.874 24.9179 26.1011C24.7028 26.3162 24.4279 26.4238 24.0933 26.4238C23.7587 26.4238 23.4778 26.3162 23.2508 26.1011C23.0237 25.874 22.9102 25.5932 22.9102 25.2585V15.9367C22.9102 14.3592 22.5636 13.1521 21.8704 12.3156C21.1892 11.467 20.0061 11.0428 18.321 11.0428C16.7793 11.0428 15.626 11.4312 14.8611 12.208C14.1082 12.9729 13.7318 14.0544 13.7318 15.4527V25.2585C13.7318 25.5932 13.6182 25.874 13.3912 26.1011C13.176 26.3162 12.9012 26.4238 12.5665 26.4238C12.2319 26.4238 11.9511 26.3162 11.724 26.1011C11.4969 25.874 11.3834 25.5932 11.3834 25.2585V15.9367C11.3834 14.3472 11.0547 13.1342 10.3974 12.2976C9.74011 11.4611 8.56293 11.0428 6.86588 11.0428C5.24054 11.0428 4.08128 11.4909 3.38812 12.3873C2.69496 13.2716 2.34838 14.5385 2.34838 16.1877V25.2585C2.34838 25.5932 2.23485 25.874 2.00778 26.1011C1.79266 26.3162 1.51778 26.4238 1.18315 26.4238C0.848525 26.4238 0.567675 26.3162 0.340605 26.1011C0.113535 25.874 0 25.5932 0 25.2585Z" fill="white"/>
                        <path d="M29.6147 25.2585V10.3436C29.6147 10.009 29.7282 9.73413 29.9553 9.51901C30.1824 9.29194 30.4632 9.17841 30.7979 9.17841C31.1325 9.17841 31.4074 9.29194 31.6225 9.51901C31.8496 9.73413 31.9631 10.009 31.9631 10.3436V25.2585C31.9631 25.5932 31.8496 25.874 31.6225 26.1011C31.4074 26.3162 31.1325 26.4238 30.7979 26.4238C30.4632 26.4238 30.1824 26.3162 29.9553 26.1011C29.7282 25.874 29.6147 25.5932 29.6147 25.2585Z" fill="white"/>
                        <path d="M36.7495 25.2585V10.3436C36.7495 10.009 36.863 9.73413 37.0901 9.51901C37.3172 9.29194 37.598 9.17841 37.9326 9.17841C38.2673 9.17841 38.5421 9.29194 38.7573 9.51901C38.9843 9.73413 39.0979 10.009 39.0979 10.3436V11.5268C39.4683 10.7858 40.0898 10.1763 40.9622 9.69828C41.8347 9.20828 42.8445 8.96329 43.9918 8.96329C45.0794 8.96329 46.0295 9.09475 46.8421 9.35767C47.6668 9.60864 48.3181 9.9373 48.7961 10.3436C49.2861 10.75 49.6805 11.2459 49.9793 11.8315C50.2781 12.4171 50.4753 12.9968 50.5709 13.5704C50.6784 14.1441 50.7322 14.7715 50.7322 15.4527V25.2585C50.7322 25.5932 50.6187 25.874 50.3916 26.1011C50.1765 26.3162 49.9016 26.4238 49.567 26.4238C49.2324 26.4238 48.9515 26.3162 48.7244 26.1011C48.4974 25.874 48.3838 25.5932 48.3838 25.2585V15.9367C48.3838 15.1599 48.3181 14.4966 48.1866 13.9469C48.0671 13.3852 47.846 12.8773 47.5234 12.4231C47.2126 11.957 46.7645 11.6104 46.1789 11.3834C45.5933 11.1563 44.8642 11.0428 43.9918 11.0428C40.7292 11.0428 39.0979 12.7577 39.0979 16.1877V25.2585C39.0979 25.5932 38.9843 25.874 38.7573 26.1011C38.5421 26.3162 38.2673 26.4238 37.9326 26.4238C37.598 26.4238 37.3172 26.3162 37.0901 26.1011C36.863 25.874 36.7495 25.5932 36.7495 25.2585Z" fill="white"/>
                        <path d="M54.0486 17.7652C54.0486 11.8973 56.5046 8.96329 61.4165 8.96329C62.5159 8.96329 63.5079 9.19633 64.3923 9.66242C65.2766 10.1285 65.9519 10.6902 66.418 11.3475V1.16523C66.418 0.830598 66.5255 0.555724 66.7406 0.340605C66.9677 0.113535 67.2486 0 67.5832 0C67.9178 0 68.1987 0.113535 68.4257 0.340605C68.6528 0.555724 68.7663 0.830598 68.7663 1.16523V19.3786C68.7663 21.721 68.109 23.5137 66.7944 24.7566C65.4798 25.9995 63.7409 26.621 61.5778 26.621C59.1637 26.621 57.3053 25.886 56.0026 24.416C54.7 22.9341 54.0486 20.7171 54.0486 17.7652ZM56.397 17.7652C56.397 22.2469 58.1239 24.4877 61.5778 24.4877C63.0597 24.4877 64.2369 24.0575 65.1093 23.197C65.9818 22.3365 66.418 21.0637 66.418 19.3786V14.3233C66.3821 14.2397 66.3224 14.1261 66.2387 13.9827C66.167 13.8274 65.9877 13.5644 65.7009 13.194C65.426 12.8235 65.1153 12.4948 64.7687 12.208C64.4341 11.9212 63.9799 11.6642 63.4063 11.4372C62.8326 11.1981 62.2231 11.0786 61.5778 11.0786C59.8927 11.0786 58.608 11.6343 57.7236 12.7458C56.8392 13.8572 56.397 15.5304 56.397 17.7652Z" fill="white"/>
                        <path d="M72.0828 17.7652C72.0828 11.8973 74.5387 8.96329 79.4506 8.96329C80.5501 8.96329 81.542 9.19633 82.4264 9.66242C83.3108 10.1285 83.986 10.6902 84.4521 11.3475V1.16523C84.4521 0.830598 84.5597 0.555724 84.7748 0.340605C85.0018 0.113535 85.2827 0 85.6173 0C85.952 0 86.2328 0.113535 86.4599 0.340605C86.6869 0.555724 86.8005 0.830598 86.8005 1.16523V19.3786C86.8005 21.721 86.1432 23.5137 84.8286 24.7566C83.5139 25.9995 81.7751 26.621 79.6119 26.621C77.1978 26.621 75.3394 25.886 74.0368 24.416C72.7341 22.9341 72.0828 20.7171 72.0828 17.7652ZM74.4311 17.7652C74.4311 22.2469 76.1581 24.4877 79.6119 24.4877C81.0939 24.4877 82.271 24.0575 83.1435 23.197C84.0159 22.3365 84.4521 21.0637 84.4521 19.3786V14.3233C84.4162 14.2397 84.3565 14.1261 84.2728 13.9827C84.2011 13.8274 84.0219 13.5644 83.735 13.194C83.4602 12.8235 83.1494 12.4948 82.8029 12.208C82.4682 11.9212 82.0141 11.6642 81.4404 11.4372C80.8668 11.1981 80.2573 11.0786 79.6119 11.0786C77.9268 11.0786 76.6421 11.6343 75.7577 12.7458C74.8733 13.8572 74.4311 15.5304 74.4311 17.7652Z" fill="white"/>
                        <path d="M91.5689 25.2585V10.3436C91.5689 10.009 91.6825 9.73413 91.9096 9.51901C92.1366 9.29194 92.4175 9.17841 92.7521 9.17841C93.0867 9.17841 93.3616 9.29194 93.5767 9.51901C93.8038 9.73413 93.9173 10.009 93.9173 10.3436V11.957C94.2998 11.0846 94.9391 10.3795 95.8355 9.84169C96.7318 9.30389 97.7954 9.03499 99.0264 9.03499C99.3371 9.03499 99.6001 9.14853 99.8152 9.3756C100.042 9.59072 100.156 9.85364 100.156 10.1644C100.156 10.4751 100.042 10.744 99.8152 10.9711C99.6001 11.1981 99.3371 11.3117 99.0264 11.3117C97.3891 11.3117 96.1283 11.8315 95.2439 12.8713C94.3595 13.911 93.9173 15.2973 93.9173 17.0302V25.2585C93.9173 25.5932 93.8038 25.874 93.5767 26.1011C93.3616 26.3162 93.0867 26.4238 92.7521 26.4238C92.4175 26.4238 92.1366 26.3162 91.9096 26.1011C91.6825 25.874 91.5689 25.5932 91.5689 25.2585Z" fill="white"/>
                        <path d="M101.411 21.3685C101.411 19.7551 101.984 18.4883 103.132 17.568C104.279 16.6478 105.844 16.1877 107.828 16.1877H113.009V15.2017C113.009 13.9588 112.621 12.9729 111.844 12.2439C111.067 11.5148 110.009 11.1503 108.671 11.1503C107.715 11.1503 106.908 11.2997 106.251 11.5985C105.605 11.8973 104.996 12.3753 104.422 13.0326C104.124 13.3792 103.801 13.5525 103.454 13.5525C103.155 13.5525 102.905 13.4509 102.701 13.2477C102.498 13.0326 102.397 12.7757 102.397 12.4769C102.397 12.2618 102.456 12.0526 102.576 11.8495C103.126 10.977 103.914 10.2779 104.942 9.75206C105.97 9.22621 107.213 8.96329 108.671 8.96329C110.774 8.96329 112.4 9.52499 113.547 10.6484C114.706 11.7718 115.286 13.2896 115.286 15.2017V25.2944C115.286 25.6051 115.172 25.874 114.945 26.1011C114.718 26.3162 114.449 26.4238 114.139 26.4238C113.828 26.4238 113.559 26.3162 113.332 26.1011C113.117 25.874 113.009 25.6051 113.009 25.2944V23.9678C112.567 24.8044 111.862 25.4557 110.894 25.9218C109.938 26.3879 108.892 26.621 107.757 26.621C105.785 26.621 104.231 26.1609 103.096 25.2406C101.972 24.3204 101.411 23.0297 101.411 21.3685ZM103.795 21.3685C103.795 22.3485 104.141 23.1133 104.835 23.6631C105.528 24.2128 106.573 24.4877 107.972 24.4877C109.346 24.4877 110.529 24.0754 111.521 23.2508C112.513 22.4261 113.009 21.2729 113.009 19.7909V18.2493H107.828C106.585 18.2493 105.599 18.5122 104.87 19.038C104.153 19.5639 103.795 20.3407 103.795 21.3685Z" fill="white"/>
                        <path d="M118.369 11.3654C118.166 11.1503 118.064 10.8874 118.064 10.5767C118.064 10.266 118.166 10.003 118.369 9.78791C118.584 9.57279 118.847 9.46523 119.158 9.46523H120.413V7.29612C120.413 6.44759 120.532 5.65285 120.771 4.91188C121.022 4.17092 121.393 3.50763 121.883 2.92203C122.385 2.32448 123.048 1.85241 123.873 1.50583C124.709 1.15925 125.671 0.985962 126.759 0.985962C127.07 0.985962 127.332 1.09352 127.548 1.30864C127.763 1.52376 127.87 1.78668 127.87 2.09741C127.87 2.40814 127.763 2.67106 127.548 2.88618C127.332 3.08935 127.07 3.19093 126.759 3.19093C125.384 3.19093 124.375 3.56141 123.729 4.30238C123.084 5.03139 122.761 6.0293 122.761 7.29612V9.46523H126.113C126.424 9.46523 126.681 9.57279 126.884 9.78791C127.099 10.003 127.207 10.266 127.207 10.5767C127.207 10.8874 127.099 11.1503 126.884 11.3654C126.681 11.5686 126.424 11.6702 126.113 11.6702H122.761V25.2585C122.761 25.5932 122.648 25.874 122.421 26.1011C122.205 26.3162 121.931 26.4238 121.596 26.4238C121.261 26.4238 120.98 26.3162 120.753 26.1011C120.526 25.874 120.413 25.5932 120.413 25.2585V11.6702H119.158C118.847 11.6702 118.584 11.5686 118.369 11.3654Z" fill="white"/>
                        <path d="M128.982 11.3654C128.779 11.1503 128.677 10.8874 128.677 10.5767C128.677 10.266 128.779 10.003 128.982 9.78791C129.197 9.57279 129.46 9.46523 129.77 9.46523H131.205V5.61102C131.205 5.30029 131.324 5.03139 131.563 4.80432C131.802 4.5653 132.077 4.44579 132.388 4.44579C132.71 4.44579 132.985 4.55933 133.212 4.7864C133.439 5.01347 133.553 5.28834 133.553 5.61102V9.46523H135.955C136.266 9.46523 136.523 9.57279 136.726 9.78791C136.941 10.003 137.049 10.266 137.049 10.5767C137.049 10.8874 136.941 11.1503 136.726 11.3654C136.523 11.5686 136.266 11.6702 135.955 11.6702H133.553V21.7987C133.553 22.5755 133.744 23.1791 134.127 23.6093C134.521 24.0276 135.041 24.2367 135.686 24.2367C136.009 24.2367 136.278 24.3443 136.493 24.5594C136.72 24.7626 136.834 25.0195 136.834 25.3303C136.834 25.641 136.72 25.9039 136.493 26.119C136.278 26.3222 136.009 26.4238 135.686 26.4238C134.264 26.4238 133.159 25.9995 132.37 25.151C131.593 24.3025 131.205 23.185 131.205 21.7987V11.6702H129.77C129.46 11.6702 129.197 11.5686 128.982 11.3654Z" fill="white"/>
                        <path d="M139.379 24.7021C139.379 23.5309 139.953 22.9453 141.1 22.9453C142.247 22.9453 142.821 23.5309 142.821 24.7021C142.821 25.8494 142.247 26.4231 141.1 26.4231C139.953 26.4231 139.379 25.8494 139.379 24.7021Z" fill="#F39748"/>
                    </svg>
                </div>

                <div class="footer-navigation" aria-hidden="true">
                    ${self.navMenus?.map((menu) => html`
                        <nav aria-describedby="offer-menu-${menu.id}">
                            <h4 id="offer-menu-${menu.id}">${menu.label}</h4>
                            ${menu.subMenuLinks.map((item) => html`<a @click=${function(){pushGAEvent('click_nav_footer', {'target': item.href})}} href="${item.href}" target="${item.target}">${item.label}</a>`)}
                        </nav>
                    `)}
                    <nav aria-describedby="md-menu">
                        <h4 id="md-menu">minddraft</h4>
                        ${self.navLinks?.map((item) => html`<a @click=${function(){pushGAEvent('click_nav_footer', {'target': item.href})}} href="${item.href}" target="${item.target}">${item.label}</a>`)}
                    </nav>
                </div>

                <nav class="footer-social-navigation">
                    ${self.content?.navpointsSocialMedia.map((item) => html`<a @click=${function(){pushGAEvent('click_nav_social', {'target': item.label})}} href="${item.href}" target="${item.target}">${item.label}</a>`)}   
                </nav>
            
                <p class="footer-meta-navigation"><span>&copy; ${`${new Date().getFullYear()} ${self.content?.companyLabel}`}</span> ${self.content?.navpointsFooter.map((item) => html`<a @click=${function(){pushGAEvent('click_nav_footer', {'target': item.href})}} href="${item.href}" target="${item.target}">${item.label}</a>`)}</p>
            </div>
        </div>

        <div class="bottom-line">
            <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.9719 1.31586L3.41857 31.2109C2.69471 31.8195 1.61032 31.7061 1.02807 30.9609C0.445811 30.2156 0.598106 29.136 1.3638 28.5809L38.9719 1.31586Z" fill="url(#paint0_linear_7159_501)"/>
                <path d="M36.703 8.64551L17.2552 30.2284C16.5792 30.9787 15.3897 30.937 14.7679 30.1411C14.2397 29.4651 14.3282 28.4948 14.97 27.9255L36.703 8.64551Z" fill="url(#paint1_linear_7159_501)"/>
                <path d="M24.0572 5.98119L6.66242 18.2934C5.968 18.785 5.00994 18.6443 4.48616 17.9738C3.86742 17.1819 4.12735 16.0219 5.02488 15.5698L24.0572 5.98119Z" fill="url(#paint2_linear_7159_501)"/>
                <defs>
                <linearGradient id="paint0_linear_7159_501" x1="38.9719" y1="1.31586" x2="1.02807" y2="30.9609" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC445"/>
                <stop offset="1" stop-color="#E5822E"/>
                </linearGradient>
                <linearGradient id="paint1_linear_7159_501" x1="38.356" y1="10.7612" x2="14.3066" y2="29.5507" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC445"/>
                <stop offset="1" stop-color="#E5822E"/>
                </linearGradient>
                <linearGradient id="paint2_linear_7159_501" x1="22.6594" y1="4.1921" x2="4.68832" y2="18.2326" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCC445"/>
                <stop offset="1" stop-color="#E5822E"/>
                </linearGradient>
                </defs>
            </svg>
            <p>where impact is made<span>.</span></p>
        </div> 

    `;
}