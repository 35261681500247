"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Class APIError 
 * 	----------------------------------------------------------------------------------------------
 */
class APIError{
	 
	constructor(obj){
		this.message = obj.message || obj;
		this.code = obj.code || obj.errCode || -1;
		this.key = obj.message_key || "";
		this.type = obj.type || "";
		this.isError = true;
	}
 }

export {APIError}
