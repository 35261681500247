"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './navigation.template.js';
import TEMPLATE_CSS from './navigation.styles.scss';
import { pushGAEvent } from '../../../js/utils/Utils';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Navigation Component
 * 	----------------------------------------------------------------------------------------------
 */
class AppNavigation extends LitElement {
    
    static properties = {
		currentNavpoint: {type: String},
		currentSubNavpoint: {type: String},
		subNavLinks: {type: Array},
		isOpen: {type: String},
    };

	constructor() {
		super();

		this.mainNavMenues = app.i18n.global?.navMenus || [];
		this.mainNavLinks = app.i18n.global?.navLinks || [];
		this.mainNavLanguages = Array.from(app.i18n.languages) || [];
		this.subNavLinks = [];


		this.currentNavpoint = "";
		this.currentSubNavpoint = "";
		this.isOpen = "";
		this._closingHandler = this.closingHandler.bind(this);

        app.router.addEventListener("routeChanged", (evt) => {
			this.clearNavigation();
			const routeData = evt.detail;
			this.setNavigation(routeData.path);
		})

		const routeData = app.router.getLastRoute();
		//console.log("routeData", routeData);
		if(routeData){
			this.setNavigation(routeData.path);
		}	
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}


	setOpenClasses() {
		document.querySelector("html").classList.add("menu-open");
		this.renderRoot.host.style.transform = 'translateX(calc(var(--scrollbar-size) *-1))';
		this.renderRoot.host.classList.add('is-transparent');
	}
	removeOpenClasses() {
		document.querySelector("html").classList.remove("menu-open");
		this.renderRoot.host.style.transform = 'none';
		this.renderRoot.host.classList.remove('is-transparent');
	}



    openNavMenu(navpoint){
		if(this.isOpen) {
			if(navpoint != this.currentNavpoint){
				this.renderRoot.querySelector(".sub-navigation").classList.add("is-changing");					
				setTimeout(() => {
					this.renderRoot.querySelector(".sub-navigation").classList.remove("is-changing");
				}, 150);
			}
		} else {
			window.addEventListener("click", this._closingHandler);
		}		
		this.isOpen = navpoint;
		this.subNavLinks = this.mainNavMenues.find(({ href }) => href === navpoint).subMenuLinks;
		this.setOpenClasses();

		pushGAEvent('toggle_nav', {'state':'open', 'target': navpoint});
    }

	/*
	setNavigation(path){
		const pathParts = path.split("/");
		this.currentNavpoint = `/${pathParts[1]}/${pathParts[2]}`;
		this.currentSubNavpoint = (pathParts.length > 3) ? `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}` : "";
	}*/

	setNavigation(path){
		const pathParts = path.split("/");
		const currentLanguage = app.i18n.getLanguage();
	
		// Wenn die aktuelle Sprache Deutsch ist und das erste Segment keine Sprache ist, 
		// behandeln Sie es als Teil des Navigationspfads
		if (currentLanguage === 'de' && !this.mainNavLanguages.includes(pathParts[1])) {
			this.currentNavpoint = `/${pathParts[1]}`;
			this.currentSubNavpoint = (pathParts.length > 2) ? `/${pathParts[1]}/${pathParts[2]}` : "";
		} else {
			// Überprüfen Sie, ob das erste Segment eine Sprache ist
			if (this.mainNavLanguages.includes(pathParts[1])) {
				this.currentNavpoint = `/${pathParts[1]}/${pathParts[2]}`;
				this.currentSubNavpoint = (pathParts.length > 3) ? `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}` : "";
			} else {
				// Wenn das erste Segment keine Sprache ist, behandeln Sie es als Teil des Navigationspfads
				this.currentNavpoint = `/${pathParts[1]}`;
				this.currentSubNavpoint = (pathParts.length > 2) ? `/${pathParts[1]}/${pathParts[2]}` : "";
			}
		}
	}

	closestPassShadow(node, selector) {
		if (!node) {
			return null;
		}
		if (node instanceof ShadowRoot) {
			return this.closestPassShadow(node.host, selector);
		}
		if (node instanceof HTMLElement) {
			if (node.matches(selector)) {
				return node;
			} else {
				return this.closestPassShadow(node.parentNode, selector);
			}
		}
		return this.closestPassShadow(node.parentNode, selector);
	}

	closingHandler(evt){
		if(!evt.composedPath().includes(this)){
			this.subNavLinks = [];
			this.isOpen = "";
			this.removeOpenClasses();
			pushGAEvent('toggle_nav', {'state':'close'});
		}
	}

	clearNavigation(){
		this.subNavLinks = [];
		this.isOpen = "";
		this.currentNavpoint = "";
		this.currentSubNavpoint = "";
		window.removeEventListener("click", this._closingHandler);
		document.querySelector("html").classList.remove("menu-open");
	}
}

customElements.define('app-navigation', AppNavigation);

export default AppNavigation;