"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './footer.template.js';
import TEMPLATE_CSS from './footer.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Button Component
 * 	----------------------------------------------------------------------------------------------
 */
 export default class AppFooter extends LitElement {
    
	constructor() {
		super();

		this.content = app.i18n.global.footer;
		this.navMenus = app.i18n.global.navMenus;
		this.navLinks = app.i18n.global.navLinks;
		this.navLanguages = Array.from(app.i18n.languages) || [];
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}
}

customElements.define('app-footer', AppFooter);