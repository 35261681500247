import { html } from 'lit';
import { navTo, pushGAEvent } from '../../../js/utils/Utils'
import '../../elements/icon/icon.controller';
import '../language-switch/language-switch.controller';

export default function(self) {
    return html`        
        <nav class="mobile-navigation" role="navigation">

            <a @click=${function(){pushGAEvent('mobile_click_contact_chat')}} href="javascript:void(Tawk_API.toggle())" class="mobile-action" aria-label="Chat">
                <adm-icon icon="#chat"></adm-icon>
            </a>
            <button class="mobile-menu-toggle ${self.menuOpen ? 'is-open' : ''}" @click="${self.toggleMenu}" aria-label="Menu"> 
                <span></span>
                <span></span>
                <span></span>
            </button>
            <a @click=${function(){pushGAEvent('mobile_click_contact_phone')}} href="tel:+41415620066" class="mobile-action" aria-label="Anrufen">
                <adm-icon icon="#phone"></adm-icon>
            </a>

            <div class="mobile-menu ${self.menuOpen ? 'is-open' : ''}">
                <div class="mobile-menu-level-1 ${self.isOpen ? 'level-2-is-open' : ''}">
                    ${self.mainNavMenues.map((item) => { 
                        return html`<button @click="${(evt) => self.openSubNav(item.href)}" class="${self.currentNavpoint === item.href ? 'is-active' : ''} ${self.isOpen === item.href ? 'is-open' : ''}">${item.label} <adm-icon icon="#arrow-right"></adm-icon></button>`
                    })}
                    ${self.mainNavLinks.map((item) => { 
                        return html`<a @click="${navTo}" class="${self.currentNavpoint === item.href ? 'is-active' : ''}" href="${item.href}">${item.label}</a>`
                    })}
                    <adm-language-switch .languages="${self.mainNavLanguages}" .currentLanguage="${app.i18n.getLanguage()}"></adm-language-switch>
                </div>

                <div class="mobile-menu-level-2 ${self.isOpen ? 'is-open' : ''}">
                    
                    <button @click="${self.closeSubNav}" aria-label="Zurück zu ${self.currentNavpointLabel}"><adm-icon icon="#arrow-right"></adm-icon> ${self.currentNavpointLabel}</button>
                    <div class="menu-group ${self.isOpen ? 'is-open' : ''}">
                        ${self.subNavLinks.map((item) => { 
                            return html`
                                <a @click="${navTo}" class="${self.currentSubNavpoint === item.href ? 'is-active' : ''}" href="${item.href}">${item.label}</a>`
                        })}
                    </div>
                </div>

            </div>
        </nav>
    `;
}