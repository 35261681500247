"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './logo.template.js';
import TEMPLATE_CSS from './logo.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Logo Component
 * 	----------------------------------------------------------------------------------------------
 */
 export default class AppLogo extends LitElement {
    
	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}
}

customElements.define('app-logo', AppLogo);