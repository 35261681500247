"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './language-switch.template.js';
import TEMPLATE_CSS from './language-switch.styles.scss';

import { configRoutes } from "../../../js/routes.js";

/**
 *	----------------------------------------------------------------------------------------------
 *	Class LanguageSwitch Component
 * 	----------------------------------------------------------------------------------------------
 */
class AdmLanguageSwitch extends LitElement {
    
	static get properties() {
		return {
			currentLanguage: { type: String },
			languages: { type: Array },
		};
	}

	constructor() {
		super();	

		this.currentLanguage = "";
		this.languages = []; 
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}

	changeLanguage(evt){
		evt.preventDefault();
		const language = evt.target.dataset.value;
		const path = app.router.getLastRoute().config.langPaths[language];
		app.i18n.changeLanguage(language, path);
	}
}

customElements.define('adm-language-switch', AdmLanguageSwitch);

export default AdmLanguageSwitch;