/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {html} from 'lit';

import AppLogo from '../../elements/logo/logo.controller';
import AppNavigation from '../../components/navigation/navigation.controller';

/**
 *	----------------------------------------------------------------------------------------------
 *	Template
 * 	----------------------------------------------------------------------------------------------
 */
export default function(self) {
    return html`
        <div class="inner">
            <a href="/${app.i18n.isDefaultLanguage() ? '' : app.i18n.getLanguage()}">
                <app-logo></app-logo>
            </a>
            <app-navigation></app-navigation>
        </div>
    `;
}