"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import AppHeader from "../../sections/header/header.controller";
import AppMobileNavigation from "../../components/mobile-navigation/mobile-navigation.controller";
 

import TEMPLATE_HTML from './simpleLayout.template.js';
import TEMPLATE_CSS from './simpleLayout.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class AppSimpleLayout
 * 	----------------------------------------------------------------------------------------------
 */
class AppSimpleLayout extends LitElement {
    
	createRenderRoot() {
		return this;
	}

	firstUpdated(){
		this.init();
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML()}`;
	}
}

customElements.define('app-simple-layout', AppSimpleLayout);

export default AppSimpleLayout;