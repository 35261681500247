"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports 
 * 	----------------------------------------------------------------------------------------------
 */
import { strapiAPI } from "./api/StrapiAPI";

const API_CONFIG_FILE = process.env.APP_CONFIG.api.file;

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Loader 
 * 	----------------------------------------------------------------------------------------------
 */
 class Loader{

	static async loadPage(pageData){
		console.log(pageData);
		if( !app.i18n?.global ){
			const result = await strapiAPI.loadGlobalData().catch(err => { console.error(err); });
			
			if(result.contactOptions){
				result.contactOptions.forEach((item) => {
					if(item.icon){
						item.icon = API_CONFIG_FILE.endpoint + item.icon.url;
					}
				})	
			}
			app.i18n.global = result;
		}
		const page = pageData.config.page;
		const Module = await import(/* webpackChunkName: "page" */ `../templates/pages/${page}/${page}.controller.js`).catch((err) => { console.error(err); });
		return Module;
	}

	static async loadView(pageData){
		const page = pageData.config.page;
		const view = pageData.config.view;
		const Module = await import(/* webpackChunkName: "view" */ `../templates/pages/${page}/views/${view}/${view}.controller.js`).catch((err) => { console.error(err); });
		return Module;
	}
	
	static redirectPage(pageData){
		location.replace(`/${app.i18n.getLanguage()}${pageData.config.redirect}`);
	}
}

export { Loader };