"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import TEMPLATE_HTML from './mobile-navigation.template.js';
import TEMPLATE_CSS from './mobile-navigation.styles.scss';
import { pushGAEvent } from '../../../js/utils/Utils';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Mobile Navigation Component
 * 	----------------------------------------------------------------------------------------------
 */
class AppMobileNavigation extends LitElement {
    
    static properties = {
		currentNavpoint: {type: String},
		currentSubNavpoint: {type: String},
		subNavLinks: {type: Array},
		isOpen: {type: String},

		currentNavpointLabel: {type: String},
		menuOpen: {type: Boolean},
    };

	constructor() {
		super();

		this.menuOpen = false;
		this.currentNavpointLabel = "";

		this.mainNavMenues = app.i18n.global?.navMenus || [];
		this.mainNavLinks = app.i18n.global?.navLinks || [];
		this.mainNavLanguages = Array.from(app.i18n.languages) || [];
		this.subNavLinks = [];

		this.currentNavpoint = "";
		this.currentSubNavpoint = "";
		this.isOpen = "";
		this._closingHandler = this.closingHandler.bind(this);


		// auto hide show feature 
		this.config = {
			sticky: true,
			// when true, bottom nav will be hidden when the autoHideOffset is reached
			autoHide: true,
			autoHideOffset: 300,
			autoHideShowOffset: 0,
			glidingOffset: 100, // value in px, when the is-gliding class is set
		};
		this.ticking = false;
		this.currentScrollY = 0;
		this.lastScrollY = 0;


        app.router.addEventListener("routeChanged", (evt) => {
			this.clearNavigation();
			const routeData = evt.detail;
			this.setNavigation(routeData.path);
		})

		const routeData = app.router.getLastRoute();
		if(routeData){
			this.setNavigation(routeData.path);
		}	
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML(this)}`;
	}

	toggleMenu(){
		if(this.menuOpen){
			this.clearNavigation();
			pushGAEvent('toggle_nav_mobile', {'state':'close'});
		}else{
			this.menuOpen = true;
			window.addEventListener("click", this._closingHandler);
			document.querySelector("html").classList.add("menu-open");

			pushGAEvent('toggle_nav_mobile', {'state':'open'});
		}
	}

    openSubNav(navpoint){
		this.isOpen = navpoint;
		const selectedMainNavMenue = this.mainNavMenues.find(({ href }) => href === navpoint);
		this.subNavLinks = selectedMainNavMenue.subMenuLinks;
		this.currentNavpointLabel = selectedMainNavMenue.label;

		// analytics
		pushGAEvent('toggle_nav_mobile', {'state': 'open', 'target': this.currentNavpointLabel});
	}

	closeSubNav(){
		this.isOpen = "";
		this.subNavLinks = [];
		this.currentNavpointLabel = "";
	}

	setNavigation(path){
		const pathParts = path.split("/");
		const currentLanguage = app.i18n.getLanguage();
	
		if (currentLanguage === 'de' && !this.mainNavLanguages.includes(pathParts[1])) {
			this.currentNavpoint = `/${pathParts[1]}`;
			this.currentSubNavpoint = (pathParts.length > 2) ? `/${pathParts[1]}/${pathParts[2]}` : "";
		} else {
			
			if (this.mainNavLanguages.includes(pathParts[1])) {
				this.currentNavpoint = `/${pathParts[1]}/${pathParts[2]}`;
				this.currentSubNavpoint = (pathParts.length > 3) ? `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}` : "";
			} else {
				this.currentNavpoint = `/${pathParts[1]}`;
				this.currentSubNavpoint = (pathParts.length > 2) ? `/${pathParts[1]}/${pathParts[2]}` : "";
			}
		}
	}



	closingHandler(evt){
		if(!evt.composedPath().includes(this)){
			this.clearNavigation();
		}
	}

	clearNavigation(){
		this.menuOpen = false;
		// set a delay of 500ms to prevent slide flickering when closing the menu.
		setTimeout(() => {
			this.closeSubNav();
		}, 500);
		
		this.currentNavpoint = "";
		this.currentSubNavpoint = "";
		window.removeEventListener("click", this._closingHandler);
		document.querySelector("html").classList.remove("menu-open")
	
	}

	show() {
		this.classList.remove("is-hidden");
	}
	hide() {
		this.classList.add("is-hidden");
	}

	gliding(state) {
		if (state == false) {
			this.classList.remove("is-gliding")
		} else {
			this.classList.add("is-gliding")
		}
	}
	
	updater() {
		
		if(this.currentScrollY > 0) {			
			// set the sticky / gliding classes
			if (this.currentScrollY > this.config.glidingOffset) {
				this.gliding();
			} else if (this.currentScrollY < this.config.glidingOffset) {
				this.gliding(false);
			}

			// set the autohide classes if autohide is true
			if(this.config.autoHide) {
				if (this.currentScrollY < this.config.autoHideOffset) {
					this.show();
				} else if (this.currentScrollY < this.lastScrollY) {
					this.show();
				} else if (this.currentScrollY > this.lastScrollY) {
					this.hide(); 
				}
			}
		} else {
			this.gliding(false);
		}
	
		this.lastScrollY = this.currentScrollY;
		this.ticking = false;
	}


	scrolling() {
		this.currentScrollY = window.pageYOffset;	
		//console.log("scrolly: "+ window.pageYOffset);
		if (!this.ticking) {
			requestAnimationFrame(() => { this.updater(); });
		}
		this.ticking = true;
	}
	
	firstUpdated() {
		if(this.config.sticky) {
			document.addEventListener("scroll", () => { this.scrolling(); }, {passive: true});
		}
	}










}

customElements.define('app-mobile-navigation', AppMobileNavigation);

export default AppMobileNavigation;