"use strict"
/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import AppSimpleLayout from "../templates/layouts/simple/simpleLayout.controller";
import AppBasicLayout from "../templates/layouts/basic/basicLayout.controller";

/**
 *	----------------------------------------------------------------------------------------------
 *	Array Routes
 * 	----------------------------------------------------------------------------------------------
 */
 export const configRoutes = [
	// active
	{langPaths: {de: "/", en: '/en'},	page: "start",	layout: AppBasicLayout},
	{langPaths: {de: "/kompetenzen/ux-design", en: '/en/competencies/ux-design'}, page: "service-ux", layout: AppBasicLayout},
	{langPaths: {de: "/kompetenzen/web-entwicklung", en: '/en/competencies/web-development'}, page: "service-web", layout: AppBasicLayout},
	{langPaths: {de: "/kompetenzen/innovative-beratung", en: '/en/competencies/innovative-consulting'}, page: "service-consulting", layout: AppBasicLayout},
	{langPaths: {de: "/kompetenzen", en: '/en/competencies'}, page: "services", layout: AppBasicLayout},
	{langPaths: {de: "/loesungen/digitale-konzepte-und-user-interfaces", en: '/en/solutions/digital-concepts-and-user-interfaces'}, page: "solution_interface", layout: AppBasicLayout},
	{langPaths: {de: "/loesungen/business-plattformen-und-apps", en: '/en/solutions/business-platforms-and-apps'}, page: "solution_platform",	layout: AppBasicLayout},
	{langPaths: {de: "/loesungen/corporate-website-experiences", en: '/en/solutions/corporate-website-experiences'}, page: "solution_relaunch",	layout: AppBasicLayout},
	{langPaths: {de: "/loesungen/design-und-entwicklung-auf-abruf", en: '/en/solutions/design-and-development-ondemand'}, page: "solution_techstack", layout: AppBasicLayout},
	{langPaths: {de: "/loesungen", en: '/en/solutions'}, page: "solutions",	layout: AppBasicLayout},
	{langPaths: {de: "/kontakt", en: '/en/contact'}, page: "contact", layout: AppBasicLayout},
	{langPaths: {de: "/ueber-uns", en: '/en/about-us'}, page: "about",	layout: AppBasicLayout},
	{langPaths: {de: "/impressum", en: '/en/imprint'},	page: "imprint", layout: AppBasicLayout},
	{langPaths: {de: "/datenschutz", en: '/en/privacy'}, page: "privacy", layout: AppBasicLayout},
	{langPaths: {de: "/blog", en: '/en/blog'}, page: "blog", layout: AppBasicLayout},
	{langPaths: {de: "/blog/european-accessibility-act-2025-was-unternehmen-jetzt-machen-muessen", en: '/en/blog/european-accessibility-act-2025-what-companies-need-to-do-now'}, page: "blog_article", layout: AppBasicLayout},
	// inactive
	{langPaths: {de: "/digital-agentur-luzern", en: '/en/digital-agency-lucerne'}, page: "agency", layout: AppBasicLayout},
	{langPaths: {de: "/digital-agentur-zuerich", en: '/en/digital-agency-zurich'}, page: "agency", layout: AppBasicLayout},
	{langPaths: {de: "/digital-agentur-zug", en: '/en/digital-agency-zug'}, page: "agency", layout: AppBasicLayout},
	{langPaths: {de: "/ux-design-agentur", en: '/en/ux-design-agency'}, page: "agency", layout: AppBasicLayout},
	{langPaths: {de: "/web-agentur", en: '/en/web-agency'}, page: "agency", layout: AppBasicLayout},
	{langPaths: {de: "/404", en: '/en/404'}, page: "404", layout: AppSimpleLayout},
];

export const getRoutes = () => {
	let routeList = [];
	configRoutes.forEach((config) => {
		for (const [key, value] of Object.entries(config.langPaths)) {
			routeList.push({path: value, config: Object.assign({lang: key}, config)});
		}
	});
	return routeList;
};