"use strict"
/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import "./../sass/styles.scss";

import { AdmApp, AdmRouter, AdmI18n, AdmPager, AdmSeo, AdmPrerender } from "@minddraft/adamantium-frontend-js";
import AppSimpleLayout from "../templates/layouts/simple/simpleLayout.controller";
import { Loader } from "./loader";
import { getRoutes } from "./routes";
import Utils from "./utils/Utils";

const APP_CONFIG = process.env.APP_CONFIG;

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Main-Application
 * 	----------------------------------------------------------------------------------------------
 */
document.addEventListener("DOMContentLoaded", async () => {

	const app = new AdmApp({
		theme: '',				// CSS Klasse von Adamantium UI: setzt css Klasse im Body-Tag
		darkMode: false,		// Toogle hell oder dunkel Ansicht abhängig davon ob adamantium UI theme das unterstützt
		page404: '404'
	});

	/**
	 * 	---------------------------------------
	 * 	Register app modules
	 * 	---------------------------------------
	 */
	app.module("pager", AdmPager, { appendTo: "#appMain", loader: Loader.loadPage, defaultLayout: AppSimpleLayout});
	app.module("i18n", AdmI18n, { languages: APP_CONFIG.settings.languages });
	app.module("router", AdmRouter, { root: '', routes: getRoutes(), listen: true });
	app.module("seo", AdmSeo, { locales: APP_CONFIG.settings.languages });
	app.module("prerender", AdmPrerender);

	/**
	 * 	---------------------------------------
	 * 	Init app
	 * 	---------------------------------------
	 */
	app.init();

	document.querySelector(':root').style.setProperty('--scrollbar-size', Utils.getScrollbarSize() + "px");
	Utils.setMousePosition(60);

	app.pager.addEventListener("pageReady", () => {
		document.querySelector('html').classList.add('is-loaded');
	});
});

