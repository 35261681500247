"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import {LitElement, html} from 'lit';

import AppHeader from "../../sections/header/header.controller";
import AppFooter from "../../sections/footer/footer.controller";
import AppMobileNavigation from "../../components/mobile-navigation/mobile-navigation.controller";
 
import TEMPLATE_HTML from './basicLayout.template.js';
import TEMPLATE_CSS from './basicLayout.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class AppBasicLayout
 * 	----------------------------------------------------------------------------------------------
 */
class AppBasicLayout extends LitElement {
    
	createRenderRoot() {
		return this;
	}

	firstUpdated(){
		this.init();
	}

	render() {
		return html`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML()}`;
	}
}

customElements.define('app-basic-layout', AppBasicLayout);

export default AppBasicLayout;