import { html } from 'lit';
import { navTo } from '../../../js/utils/Utils'

import '../../components/language-switch/language-switch.controller';

export default function(self) {
    return html`
        <nav class="navigation" role="navigation">

            
            ${self.mainNavMenues.map((item) => { 
                return html`<button @click="${(evt) => self.openNavMenu(item.href)}" class="${self.currentNavpoint === item.href ? 'is-active' : ''} ${self.isOpen === item.href ? 'is-open' : ''}">${item.label}</button>`
            })}
            ${self.mainNavLinks.map((item) => { 
                return html`<a @click="${navTo}" class="${self.currentNavpoint === item.href ? 'is-active' : ''}" href="${item.href}">${item.label}</a>`
            })}

            <div class="sub-navigation ${self.isOpen ? 'is-open' : ''}">
                <div class="sub-navigation-group ${self.isOpen ? 'is-open' : ''}">
                    ${self.subNavLinks.map((item) => { 
                        return html`
                            <a @click="${navTo}" class="${self.currentSubNavpoint === item.href ? 'is-active' : ''}" href="${item.href}">${item.label} <span>${item.description}</span></a>`
                    })}
                </div>
            </div>    
            <adm-language-switch .languages="${self.mainNavLanguages}" .currentLanguage="${app.i18n.getLanguage()}"></adm-language-switch>
        </nav>
    `;
}