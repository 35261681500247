"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports 
 * 	----------------------------------------------------------------------------------------------
 */
import { eventBus} from "@minddraft/adamantium-frontend-js";

const API_CONFIG_FILE = process.env.APP_CONFIG.api.file;

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Utils 
 * 	----------------------------------------------------------------------------------------------
 */
 class Utils{

	static navTo(event){
		event.preventDefault();
		const path = event.target.closest("a").getAttribute("href");
		eventBus.dispatchEvent("navigate", {path});
	}

	static scrollTop() {
		document.querySelector("app-header").show();
		window.scrollTo({top: 0, left: 0, behavior: "instant"});
	}

	static scrollToElement(element) {
		document.querySelector(element).scrollIntoView({block:"nearest", behavior:"smooth"});
	}

	static getScrollbarSize() {
		var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;
	}


	static checkAccess(mod, submod, func){
		var accessAllowed = false;
		if(app.auth.getAuth().data?.user_role_access){
			const accessMap = app.auth.getAuth().data.user_role_access;
			if(accessMap[mod]?.acf){
				if(submod){
					if(accessMap[mod].submodules[submod]?.acf){
						if(func){
							if(accessMap[mod].submodules[submod].functions[func]?.acf){
								accessAllowed = true;
							}
						}else{
							accessAllowed = true;
						}
					}
				}else{
					accessAllowed = true;
				}
			}
		}
		return accessAllowed;
	}

	static closestPassShadow(node, selector) {
		if (!node) {
			return null;
		}
		if (node instanceof ShadowRoot) {
			return Utils.closestPassShadow(node.host, selector);
		}
		if (node instanceof HTMLElement) {
			if (node.matches(selector)) {
				return node;
			} else {
				return Utils.closestPassShadow(node.parentNode, selector);
			}
		}
		return Utils.closestPassShadow(node.parentNode, selector);
	}

	static setMousePosition(seconds) {
		const updatesPerSecond = seconds || 1;
		let wait = false;
		document.addEventListener('mousemove', (e) => {
			if(!wait) {
				const rect = document.querySelector("body").getBoundingClientRect();
				const x = e.clientX - (rect.left + window.scrollX);
				const y = e.clientY - (rect.top + window.scrollY);
		
				document.querySelector(":root").style.setProperty("--mouse-x", `${x}px`);
				document.querySelector(":root").style.setProperty("--mouse-y", `${y}px`);

				wait = true;

				setTimeout(function () {
					wait = false;
				}, 1000 / updatesPerSecond);
			}
		});
	}

	static setPageSEO(seoData){
		// remove languages from the default url
		let defaultPathname = location.pathname;
    	const languages = Array.from(app.i18n.languages) || [];

		languages.forEach((lang) => {
			const langPath = `/${lang}`;
			if(defaultPathname.includes(langPath)) {
				defaultPathname = defaultPathname.replace(langPath, '');
			}
		});
		const urlLangList = [];
		const lastRoute = app.router.getLastRoute();
		for (const [key, value] of Object.entries(lastRoute.config.langPaths)) {
			urlLangList.push({lang: key, url: `${location.origin}${value}`});
		}

		const metadata = {
			META_LANG: 				app.i18n.getLanguage() || "",
			META_URL_DEFAULT: 		`${location.origin}${lastRoute.config.langPaths[app.i18n.getDefaultLanguage()]}`,
			META_URL_CANONICLE: 	`${location.origin}${location.pathname}`,
			META_URL_LANG_LIST: 	urlLangList,
			META_TITLE: 			seoData?.metaTitle || "",
			META_DESCRIPTION: 		seoData?.metaDescription || "",
			META_OG_TITLE: 			seoData?.metaTitle || "",
			META_OG_DESCRIPTION: 	seoData?.metaDescription || "",
			META_OG_IMAGE: 			(seoData?.metaImage?.url) ? API_CONFIG_FILE.endpoint + seoData.metaImage.url : "",
			META_OG_URL: 			`${location.origin}${location.pathname}`,
			META_TWITTER_CARD: 		"summary_large_image",
			META_TWITTER_SITE: 		"@minddraft",
			META_TWITTER_CREATOR: 	"@minddraft"		
		}
		try {
			metadata.STRUCTURED_DATA = (seoData?.structuredData) ? JSON.stringify(seoData.structuredData) : '';
		} catch (error) {
			
		}
		app.seo.setMetaData(metadata);
	}

	static pushGAEvent(eventName, params) {
	
		if (typeof gtag === 'function') {
			if (params) {
				gtag('event', eventName, params);
				console.log('event sent: ' + eventName + " Params: ", params);
			} else {
				gtag('event', eventName);
				console.log('event sent: ' + eventName);
			}
			
		}
	}
}

export const navTo = Utils.navTo;
export const scrollTop = Utils.scrollTop;
export const scrollToElement = Utils.scrollToElement;
export const checkAccess = Utils.checkAccess;
export const closestPassShadow = Utils.closestPassShadow;
export const getScrollbarSize = Utils.getScrollbarSize;
export const setMousePosition = Utils.setMousePosition;
export const setPageSEO = Utils.setPageSEO;
export const pushGAEvent = Utils.pushGAEvent;
export default Utils;